.formulation-rationale__surveys__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    grid-template-areas:
        "headerOne headerTwo"
        "contentOne contentTwo"
        "headerThree headerFour"
        "contentThree contentFour"
        "headerFive headerSix"
        "contentFive contentSix";
}

.mircobes_segment {
    padding-top: 0 !important;
}

.mircobes_segment table tr th {
    padding-top: 1rem;
}

.pastformulation {
    width: 100%;
    text-align: right;
}

.grid-1fr-block {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
    column-gap: 10px;
}

.grid-1fr-block table th {
    position: relative;
    text-align: center;
}

.types::first-letter {
    text-transform: capitalize;
}

.organism_barcode {
    text-align: center !important;
}

.previous-formulas-block {
    max-height: calc(100vh - 160px);
    overflow-y: auto;

}

.PFC-Width {
    width: 100%;
}