.css-hf3w1f-word-removed{
    background: none !important;
}
.css-1n5o7vh-diff-container{
    width: 800px !important;
}
strong{
    display: block;
    padding-bottom: 1%;
}
.post-params{
    padding-bottom: 3%;
}
.css-cnnxkz-diff-added{
    background: #f7f7f7 !important;
}
.css-cncyp1-word-diff{
    background: #f7f7f7 !important;
}
.css-rq9a2a-diff-removed{
    background: #f7f7f7 !important;
}
.css-vl0irh-content{
    background: #f7f7f7 !important;
}
.css-17vezug-marker{
    background: #f7f7f7 !important;
}
.css-1ks9aww-gutter{
    background: #f7f7f7 !important;
}
.css-17vezug-marke{
    display: none !important;
}
.css-17vezug-marker pre{
    display: none !important;
}