#example thead tr th {
  padding-left: 12px;
  height: 40px;
  min-height: 40px;
  text-align: left;
  border-right: 0.5px solid #bdc3c7;
}

#expand-table{
  width: 100%;
  border: 0.5px solid #bdc3c7;
  border-collapse: collapse;
}

.table {
  background-color: #fff;
  border: 1px solid #bdc3c7;
  border-collapse: collapse;
  width: 100%;
  border-top: 16.5px solid #bdc3c7;
}

thead {
  color: rgba(0, 0, 0, .54);
  font-weight: 600;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  border-bottom: 1px solid #bdc3c7;
}

.success-data{
  border: 0.5px solid #bdc3c7;
  height: 37px;
  min-height: 37px;
}
.success-data td{
  padding-left: 1%;
  font-size: 13px;
}

.ReactModal__Content{
  overflow: initial !important;
}
.ReactModal__Overlay{
  background-color: #000 !important;
  opacity: 0.96 !important; 
}

.tooltip-table {
  position: relative;
}

ul li{
  list-style-type: none;
  line-height: 1.3;
}
.zotero-ca-block .ca-heading {
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 4px;
  position: relative;
  margin: 1%;
  cursor: pointer;
}
.zotero-ca-block.open .ca-heading {
  border-radius: 4px 4px 0 0;
}
.zotero-ca-block .ca-body {
  border: solid 1px #e6e6e6;
  padding: 15px;
  background: #fff;
}
.ca-tags {
  margin-top: 15px;
}
.ca-publication{
  margin-top: 10px;
}
.ReactModalPortal {
  position: relative;
  z-index: 9;
}
.ReactModal__Body--open {
  overflow: hidden;
}
.ReactModal__Overlay {
  overflow-x: hidden;
  overflow-y: auto;
}
.ReactModal__Overlay::-webkit-scrollbar {
  width: 1em;
}
.ReactModal__Overlay::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.ReactModal__Overlay::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.ca-abstract{
  text-align: justify;
}
.zotero-ca-block .ca-heading:after {
  content: "\f067";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}
.zotero-ca-block.open .ca-heading:after {
content: "\f068";
}
#expand-table td{
color: green;
padding: 8px 12px;
font-size: 13px;
}

.tooltip-a>span {
  cursor: pointer;
  overflow-y: overlay;
}
.tooltip-a .tooltiptext-a {
visibility: hidden;
background-color: grey;
color: #fff;
border-radius: 6px;
padding: 5px 0;
/* width: 300px; */
width: 260px;

/* Position the tooltip */
position: absolute;
z-index: 999;
bottom: 100%;
margin: 0;
/* left: 50%; */
left: 30%;

transform: translate(-50%, 0);
}
.tooltip-a .tooltiptext-a.width-change {
  left: 50%;
  width: 300px;
}
.tooltip-a .tooltiptext-a ul {
  max-height: 250px;
  overflow: auto;
  /* padding: 3px 10px 3px 10px; */
  padding: 3px 0 3px 10px;

  width: 100%;
  margin: 0;
  text-align: left;
}
.tooltip-a .tooltiptext-a:after {
content: "";
position: absolute;
left: 50%;
margin-left: -4px;
bottom: -6px;
border-left: solid 7px transparent;
border-right: solid 7px transparent;
border-top: solid 7px grey;
}
.tooltip-a:hover .tooltiptext-a {
visibility: visible;
cursor: pointer;
}
ul li::before {
/* content: "\2022"; */
color: #fff;
font-weight: bold;
padding-right: 1%;
line-height: 1.4;
}
.tooltiptext-a li{
padding-left: 2%;
}

.tooltip-a .tooltiptext-a::-webkit-scrollbar-track {
box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.tooltip-a .tooltiptext-a::-webkit-scrollbar-thumb {
background-color: silver;
outline: 1px solid silver;
}
.success-formulla{
margin-bottom: 0 !important;
position: absolute !important;
top: -49px !important;
width: 100% !important;
outline: 15px solid #fff !important;
}
.uk-grid{
padding: 12px !important;
}
.tags-info-table-scroll {
max-height: 72vh;
overflow-y: auto;
width: 100%;
}

.tags-info-table-scroll::-webkit-scrollbar-track {
box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.tags-info-table-scroll::-webkit-scrollbar-thumb {
background-color: silver;
outline: 1px solid silver;
}
.pos-bottom.tooltip-a .tooltiptext-a {
  bottom: inherit;
  top: 100%;
  z-index: 1000;

}
.pos-bottom.tooltip-a .tooltiptext-a:after {
  border-bottom: solid 7px grey;
  border-top: inherit !important;
  top: -6px;
  bottom: inherit;
  z-index: 1000;
}