/* .formulation-rationale__health-goal-call-summary__header {
    grid-area: headerTwo;
} */

.formulation-rationale__health-goal-call-summary__content {
    grid-area: contentTwo;
    height: 15rem;
    overflow-y: scroll;
}

.formulation_ai {
    grid-area: contentTwo;
    height: 35.7rem;
    overflow-y: scroll;

}

.formulation-rationale__health-goal-call-summary__content {
    grid-area: contentTwo;
    height: 16rem; 

     overflow-y: scroll;
}
.formulation-rationale__health-goal-call-summary__content2 {
    grid-area: contentTwo;
    height: 37.5rem; 
     overflow-y: scroll;
}
.formulation-rationale__health-goal-call-summary__content2 {
    grid-area: contentTwo;
    height: 37.5rem; 
    overflow-y: scroll;
}

.formulation-rationale__influnced_microbies__header {
    position: "absolute";
    width: "40.8%";
    margin-top: "23rem";
    left: "54.7em";
}

.formulation-rationale {
    height: 35.5rem;
}

.content {
    height: "13rem";
    overflow-y: "scroll";
}


table {
    width: 100%;
    text-align: center;
}

td:first-child {
    text-align: left;
}

th:first-child {
    text-align: left;
}

table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #fff;
    border-bottom: 1px solid black;
}

.formulation_info {
    margin-bottom: 0px;
    max-height: 23%;
    overflow: auto;
}