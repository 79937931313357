.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

#prime_table > * > * > * {
  background-color: grey !important;
}

#prime_inputs > * > * > * > * {
  background-color: grey !important;
}

.ui.dimmer .ui.workaround.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.dimmer .ui.workaround.loader:after {
  border-color: #767676 transparent transparent;
}

.validation_error_input {
  border: 1px dashed red;
  color: red;
}

.validation_error_message {
  color: red;
}

.full_opacity {
  opacity: 1 !important;
  color: #03188e !important;
  /* color: #ff9800 !important; */
  background-color: #f9fafb !important;
}

.full_opacity_red {
  opacity: 1 !important;
  color: red !important;
}

.full_opacity > input {
  opacity: 1 !important;
  font-weight: bold;
  color: #000000 !important;
  background-color: #f9fafb !important;
}

.modal_header {
  text-decoration: underline;
  text-align: center;
  background-color: #ffeb3bc7 !important;
}

.transparent {
  background: rgba(255, 255, 255, 0.785) !important;
}

.black-text {
  color: black !important;
}

.pad-0 {
  padding: 0 !important;
}

#fullHeightWidth {
  height: 100vh !important;
  width: 100% !important;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.hyper-link {
  cursor: help;
  font-style: italic;
}

.hyper-link:hover {
  font-style: normal;
  text-decoration: underline;
}

.rightAlign {
  text-align: right !important;
}

.leftAlign {
  text-align: left !important;
}

.centerAlign {
  text-align: center !important;
}

.filepond--list-scroller {
      width: 80% !important;
    }