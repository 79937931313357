.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

#prime_table > * > * > * {
  background-color: grey !important;
}

#prime_inputs > * > * > * > * {
  background-color: grey !important;
}

.ui.dimmer .ui.workaround.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.dimmer .ui.workaround.loader:after {
  border-color: #767676 transparent transparent;
}

.validation_error_input {
  border: 1px dashed red;
  color: red;
}

.validation_error_message {
  color: red;
}

.full_opacity {
  opacity: 1 !important;
  color: #03188e !important;
  /* color: #ff9800 !important; */
  background-color: #f9fafb !important;
}

.full_opacity_red {
  opacity: 1 !important;
  color: red !important;
}

.full_opacity > input {
  opacity: 1 !important;
  font-weight: bold;
  color: #000000 !important;
  background-color: #f9fafb !important;
}

.modal_header {
  text-decoration: underline;
  text-align: center;
  background-color: #ffeb3bc7 !important;
}

.transparent {
  background: rgba(255, 255, 255, 0.785) !important;
}

.black-text {
  color: black !important;
}

.pad-0 {
  padding: 0 !important;
}

#fullHeightWidth {
  height: 100vh !important;
  width: 100% !important;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.hyper-link {
  cursor: help;
  font-style: italic;
}

.hyper-link:hover {
  font-style: normal;
  text-decoration: underline;
}

.rightAlign {
  text-align: right !important;
}

.leftAlign {
  text-align: left !important;
}

.centerAlign {
  text-align: center !important;
}

.filepond--list-scroller {
      width: 80% !important;
    }
#example thead tr th {
  padding-left: 12px;
  height: 40px;
  min-height: 40px;
  text-align: left;
  border-right: 0.5px solid #bdc3c7;
}

#expand-table{
  width: 100%;
  border: 0.5px solid #bdc3c7;
  border-collapse: collapse;
}

.table {
  background-color: #fff;
  border: 1px solid #bdc3c7;
  border-collapse: collapse;
  width: 100%;
  border-top: 16.5px solid #bdc3c7;
}

thead {
  color: rgba(0, 0, 0, .54);
  font-weight: 600;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  border-bottom: 1px solid #bdc3c7;
}

.success-data{
  border: 0.5px solid #bdc3c7;
  height: 37px;
  min-height: 37px;
}
.success-data td{
  padding-left: 1%;
  font-size: 13px;
}

.ReactModal__Content{
  overflow: initial !important;
}
.ReactModal__Overlay{
  background-color: #000 !important;
  opacity: 0.96 !important; 
}

.tooltip-table {
  position: relative;
}

ul li{
  list-style-type: none;
  line-height: 1.3;
}
.zotero-ca-block .ca-heading {
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 4px;
  position: relative;
  margin: 1%;
  cursor: pointer;
}
.zotero-ca-block.open .ca-heading {
  border-radius: 4px 4px 0 0;
}
.zotero-ca-block .ca-body {
  border: solid 1px #e6e6e6;
  padding: 15px;
  background: #fff;
}
.ca-tags {
  margin-top: 15px;
}
.ca-publication{
  margin-top: 10px;
}
.ReactModalPortal {
  position: relative;
  z-index: 9;
}
.ReactModal__Body--open {
  overflow: hidden;
}
.ReactModal__Overlay {
  overflow-x: hidden;
  overflow-y: auto;
}
.ReactModal__Overlay::-webkit-scrollbar {
  width: 1em;
}
.ReactModal__Overlay::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.ReactModal__Overlay::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.ca-abstract{
  text-align: justify;
}
.zotero-ca-block .ca-heading:after {
  content: "\F067";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}
.zotero-ca-block.open .ca-heading:after {
content: "\F068";
}
#expand-table td{
color: green;
padding: 8px 12px;
font-size: 13px;
}

.tooltip-a>span {
  cursor: pointer;
  overflow-y: overlay;
}
.tooltip-a .tooltiptext-a {
visibility: hidden;
background-color: grey;
color: #fff;
border-radius: 6px;
padding: 5px 0;
/* width: 300px; */
width: 260px;

/* Position the tooltip */
position: absolute;
z-index: 999;
bottom: 100%;
margin: 0;
/* left: 50%; */
left: 30%;

transform: translate(-50%, 0);
}
.tooltip-a .tooltiptext-a.width-change {
  left: 50%;
  width: 300px;
}
.tooltip-a .tooltiptext-a ul {
  max-height: 250px;
  overflow: auto;
  /* padding: 3px 10px 3px 10px; */
  padding: 3px 0 3px 10px;

  width: 100%;
  margin: 0;
  text-align: left;
}
.tooltip-a .tooltiptext-a:after {
content: "";
position: absolute;
left: 50%;
margin-left: -4px;
bottom: -6px;
border-left: solid 7px transparent;
border-right: solid 7px transparent;
border-top: solid 7px grey;
}
.tooltip-a:hover .tooltiptext-a {
visibility: visible;
cursor: pointer;
}
ul li::before {
/* content: "\2022"; */
color: #fff;
font-weight: bold;
padding-right: 1%;
line-height: 1.4;
}
.tooltiptext-a li{
padding-left: 2%;
}

.tooltip-a .tooltiptext-a::-webkit-scrollbar-track {
box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.tooltip-a .tooltiptext-a::-webkit-scrollbar-thumb {
background-color: silver;
outline: 1px solid silver;
}
.success-formulla{
margin-bottom: 0 !important;
position: absolute !important;
top: -49px !important;
width: 100% !important;
outline: 15px solid #fff !important;
}
.uk-grid{
padding: 12px !important;
}
.tags-info-table-scroll {
max-height: 72vh;
overflow-y: auto;
width: 100%;
}

.tags-info-table-scroll::-webkit-scrollbar-track {
box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.tags-info-table-scroll::-webkit-scrollbar-thumb {
background-color: silver;
outline: 1px solid silver;
}
.pos-bottom.tooltip-a .tooltiptext-a {
  bottom: inherit;
  top: 100%;
  z-index: 1000;

}
.pos-bottom.tooltip-a .tooltiptext-a:after {
  border-bottom: solid 7px grey;
  border-top: inherit !important;
  top: -6px;
  bottom: inherit;
  z-index: 1000;
}
.formulation-rationale__health-survey__header {
    grid-area: headerOne;
}

.formulation-rationale__health-survey__content {
    grid-area: contentOne;
    height: 500px;
    overflow-y: auto;
    padding-bottom: 5px;
}

.formulation-rationale__health-survey {
    grid-area: contentOne;
    height: 230px;
    overflow-y: auto;
    padding-bottom: 5px;
}

.formulation-rationale__health-survey .formulation-rationale__health-survey__content,
.formulation-rationale__health-survey__content .formulation-rationale__health-survey__content {
    grid-area: inherit;
    height: inherit;
    overflow-y: inherit;
}

.formulation-rationale__health-survey__item {
    margin-bottom: 1em;
}
.table.position-relative th {
    position: relative !important;
}
/* .formulation-rationale__health-goal-call-summary__header {
    grid-area: headerTwo;
} */

.formulation-rationale__health-goal-call-summary__content {
    grid-area: contentTwo;
    height: 15rem;
    overflow-y: scroll;
}

.formulation_ai {
    grid-area: contentTwo;
    height: 35.7rem;
    overflow-y: scroll;

}

.formulation-rationale__health-goal-call-summary__content {
    grid-area: contentTwo;
    height: 16rem; 

     overflow-y: scroll;
}
.formulation-rationale__health-goal-call-summary__content2 {
    grid-area: contentTwo;
    height: 37.5rem; 
     overflow-y: scroll;
}
.formulation-rationale__health-goal-call-summary__content2 {
    grid-area: contentTwo;
    height: 37.5rem; 
    overflow-y: scroll;
}

.formulation-rationale__influnced_microbies__header {
    position: "absolute";
    width: "40.8%";
    margin-top: "23rem";
    left: "54.7em";
}

.formulation-rationale {
    height: 35.5rem;
}

.content {
    height: "13rem";
    overflow-y: "scroll";
}


table {
    width: 100%;
    text-align: center;
}

td:first-child {
    text-align: left;
}

th:first-child {
    text-align: left;
}

table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #fff;
    border-bottom: 1px solid black;
}

.formulation_info {
    margin-bottom: 0px;
    max-height: 23%;
    overflow: auto;
}
/* .formulation-rationale__health-goal-call-summary__header {
    grid-area: headerTwo;
} */

.formulation-rationale__health-goal-call-summary__content {
    grid-area: contentTwo;
    height: 15rem;
    overflow-y: scroll;
}

.formulation_ai {
    grid-area: contentTwo;
    height: 35.7rem;
    overflow-y: scroll;

}

.formulation-rationale__health-goal-call-summary__content {
    grid-area: contentTwo;
    height: 16rem; 

     overflow-y: scroll;
}
.formulation-rationale__health-goal-call-summary__content2 {
    grid-area: contentTwo;
    height: 37.5rem; 
     overflow-y: scroll;
}
.formulation-rationale__health-goal-call-summary__content2 {
    grid-area: contentTwo;
    height: 37.5rem; 
    overflow-y: scroll;
}

.formulation-rationale__influnced_microbies__header {
    position: "absolute";
    width: "40.8%";
    margin-top: "23rem";
    left: "54.7em";
}

.formulation-rationale {
    height: 35.5rem;
}

.content {
    height: "13rem";
    overflow-y: "scroll";
}


table {
    width: 100%;
    text-align: center;
}

td:first-child {
    text-align: left;
}

th:first-child {
    text-align: left;
}

table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #fff;
    border-bottom: 1px solid black;
}

.formulation_info {
    margin-bottom: 0px;
    max-height: 23%;
    overflow: auto;
}
.formulation-rationale__surveys__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    column-gap: 10px;
    grid-template-areas:
        "headerOne headerTwo"
        "contentOne contentTwo"
        "headerThree headerFour"
        "contentThree contentFour"
        "headerFive headerSix"
        "contentFive contentSix";
}

.mircobes_segment {
    padding-top: 0 !important;
}

.mircobes_segment table tr th {
    padding-top: 1rem;
}

.pastformulation {
    width: 100%;
    text-align: right;
}

.grid-1fr-block {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
    grid-column-gap: 10px;
    column-gap: 10px;
}

.grid-1fr-block table th {
    position: relative;
    text-align: center;
}

.types::first-letter {
    text-transform: capitalize;
}

.organism_barcode {
    text-align: center !important;
}

.previous-formulas-block {
    max-height: calc(100vh - 160px);
    overflow-y: auto;

}

.PFC-Width {
    width: 100%;
}
.formulation-rationale__footer {
    color: #FFF;
    text-align: center;
    padding: 2em;
    margin-top: 500px;
}

 .pdf-wrap {
  font-family: arial;
  padding: 0 20px;
}
pre{
  display: inline-block;
  font-family: arial !important;
  line-height: 0 !important;
}
.supplement-heading {
  font-size: 48px; 
  font-weight: bold; 
  line-height: 54px;
}
.main-heading {
  font-size: 41px;
  line-height: 48px;
}
.supplement-info {
  font-size: 19px;
  font-weight: 500;
  line-height: 34px;
}
.border-top10 {
  border-top: 7px solid #000 !important;
}
.border-bottom10 {
  border-bottom: 7px solid #000 !important;
}
.info-heading {
  font-size: 19px;
  font-weight: bold;
  border-bottom: 2px solid #323232;
  padding: 10px 0 10px 0;
}
.info-heading img {
  max-width: 16px;
  vertical-align: 0px;
  padding-left: 2px;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 100%;
}
.main-row {
  display: flex;
  margin: 0 -20px;
}
.main-col {
  padding: 0 20px;
}
.info-row {
  display: flex;
  align-items: center;
  width: 100%;
  border-top:solid 2px #323232;
}
.info-col {
  font-size: 19px;
  font-weight: 500;
  padding: 11px 0;
  line-height: 22px;
  width: 85%;
}
.info-cols{
  font-size: 19px;
  font-weight: 500;
  padding: 11px 0;
  line-height: 22px;
  width: 15%;
} 

.w-33 {
  width: 50%;
}
.w-66 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
.w-25 {
  width: 25%;
}
.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.ml-auto {
  margin-left: auto;
}
.font-24 {
  font-size: 19px !important;
}
.fw-600 {
  font-weight: bold !important;
}
.info-dtl {
  font-size: 18px;
  margin-top: 13px;
  margin-bottom: 7px;
}
.info-dtl strong,
.info-dtl span {
  display:inline-block !important;
}
.mt-20 {
  margin-top: 18px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.info-dtl div {
  margin-top: 3px;
  line-height: 23px;
}
.info-dtl img {
  min-width: 14px;
  vertical-align: 0px;
  margin-right: 4px;
}
.supplement-mg {
  font-size: 17px;
  padding: 3px 0;
  width: 255px;
  display: inline-block;
  border-bottom:solid 2px #323232;
}
.supplement-mg + .supplement-mg::before {
  display: inline-block;
  white-space: pre;
  /* content: ", ";  */
}

.supplement-mg span {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  display: inline-block;
  margin-left: 7px;
  margin-right: 7px;
  vertical-align: 3px;
}
strong{
  display: initial !important;
}
.pro{
  display: inline-block;
  width: 90%;
  font-size: 18px;
  font-weight: 500;
  padding: 4px 0;
  line-height: 22px;
}
.pro-values{
  display: inline-block;
  width: 10%;
  text-align: right;
  font-weight: 600 !important;
  font-size: 19px !important;
}
.img-arrow{
  display: inline-block;
  width: 11px;
  position: relative;
  right: 2px;
}
.info-sub-row {
  display: flex;
  align-items: center;
}
.info-blank-space {
  min-width: 15px;
}
#downloadButton {
  background: white;
  cursor: pointer;
  bottom: 15px;
}
.filepond--list-scroller ul li::before {
    display: none;
}

.date-picker {
    width: inherit;
    margin-top: 3%;
    border: 1px solid gray;
    opacity: 0.4;
    padding: 4%;
    border-radius: 4px;
}

.upload-documents-input {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 3px;
    background-color: gray;
    border-radius: 4px;
}

.is-available {
    width: 100%;
    padding: 7px 0;
    border: 1px solid gray;
    /* opacity: 0.5; */
}

.is-available:focus {
    border: 1px solid gray;
}

.tooltip-name {
    border: 1px solid;
    white-space: hidden;
    overflow: hidden;
    position: absolute;
    top: 50px;
    width: 200px;
    max-width: 200px;
    background-color: black;
    color: aliceblue;
    border-radius: 4px;
    padding: 5%;
    display: none;
    z-index: 1;
}

.tooltip-name :focus {
    width: 100px;
    max-width: 100px;
}

.align-items-end {
    align-items: end
}
.field-ui {
    background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    padding: 0.67857143em 1em;
    border-radius: 0.28571429rem;
}
.inventory_upload_document {
    max-height: 435px;
    overflow-y: auto;
    overflow-x: hidden;
}
.notes{
    border-radius: 4px;
    height: 45px;
    margin-top: 1%;
}
.inventory{
    height: 34px;
    margin-top: 1px;
    opacity: 0.6;
    width: 100%;
    border: 1px solid gray;
}
.inventory:focus{
    border: 1px solid gray;
}
.isPrime{
    text-transform: capitalize;
    width: 100%;
}
.notesInventory{
    padding-left: 2%;
    line-height: 0;
}
textarea.full_opacity {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
}
textarea.full_opacity:focus {
    outline:none;
}
.cs-checkbox-label {
    margin-left: 4px;
    margin-right: 15px;
    vertical-align: 1px;
}
#page-size {
    min-width:100%;
}
.ui[class*="right labeled"].icon.button.sr-button {
    padding-right: 2.5em !important;
    padding-left: 1em !important;
}
.require_fields{
    color:red;
}
.mt-5 {
    margin-top: 0px !important;
}
.w-100 {
    width:100% !important;
}
.white-textarea {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0;
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    background: #fff;
    resize: none;
    height: 80px;
}
.pl-0 {
    padding-left: 0 !important;
}

 .pdf-wrap {
    font-family: arial;
    padding: 0 20px;
  }
  pre{
    display: inline-block;
    font-family: arial !important;
    line-height: 0 !important;
  }
  .supplement-heading {
    font-size: 48px; 
    font-weight: bold; 
    line-height: 54px;
  }
  .main-heading {
    font-size: 41px;
    line-height: 48px;
    /* padding-bottom: 10px; */
  }
  .supplement-info {
    font-size: 19px;
    font-weight: 500;
    line-height: 34px;
  }
  .border-top10 {
    border-top: 7px solid #000 !important;
  }
  .border-bottom10 {
    border-bottom: 7px solid #000 !important;
  }
  .info-heading {
    font-size: 19px;
    font-weight: bold;
    border-bottom: 2px solid #323232;
    padding: 10px 0 10px 0;
  }
  .info-heading img {
    max-width: 16px;
    vertical-align: 0px;
    padding-left: 2px;
  }
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .fw-500 {
    font-weight: 500 !important;
  }
  .w-60 {
    width: 60%;
  }
  .w-40 {
    width: 40%;
  }
  .w-50 {
    width: 100%;
  }
  .main-row {
    display: flex;
    margin: 0 -20px;
  }
  .main-col {
    padding: 0 20px;
  }
  .info-row {
    display: flex;
    align-items: center;
    width: 100%;
    border-top:solid 2px #323232;
  }
  .info-col {
    font-size: 19px;
    font-weight: 500;
    padding: 11px 0;
    line-height: 22px;
    width: 85%;
  }
  .info-cols{
    font-size: 19px;
    font-weight: 500;
    padding: 11px 0;
    line-height: 22px;
    width: 15%;
  } 
  
  .w-33 {
    width: 50%;
  }
  .w-66 {
    width: 50%;
  }
  .w-75 {
    width: 75%;
  }
  .w-25 {
    width: 25%;
  }
  .d-flex {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  .ml-auto {
    margin-left: auto;
  }
  .font-24 {
    font-size: 19px !important;
  }
  .fw-600 {
    font-weight: bold !important;
  }
  .info-dtl {
    font-size: 18px;
    margin-top: 13px;
    margin-bottom: 7px;
  }
  .info-dtl strong,
  .info-dtl span {
    display:inline-block !important;
  }
  .mt-20 {
    margin-top: 18px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .info-dtl div {
    margin-top: 3px;
    line-height: 23px;
  }
  .info-dtl img {
    min-width: 14px;
    vertical-align: 0px;
    margin-right: 4px;
  }
  .supplement-mg {
    font-size: 17px;
    padding: 3px 0;
    width: 255px;
    display: inline-block;
    border-bottom:solid 2px #323232;
  }
  .supplement-mg + .supplement-mg::before {
    display: inline-block;
    white-space: pre;
    /* content: ", ";  */
  }
  
  .supplement-mg span {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #000;
    display: inline-block;
    margin-left: 7px;
    margin-right: 7px;
    vertical-align: 3px;
  }
  strong{
    display: initial !important;
  }
  .pro{
    display: inline-block;
    width: 90%;
    font-size: 18px;
    font-weight: 500;
    padding: 4px 0;
    line-height: 22px;
  }
  .pro-values{
    display: inline-block;
    width: 10%;
    text-align: right;
    font-weight: 600 !important;
    font-size: 19px !important;
  }
  .img-arrow{
    display: inline-block;
    width: 11px;
    position: relative;
    right: 2px;
  }
  .info-sub-row {
    display: flex;
    align-items: center;
  }
  .info-blank-space {
    min-width: 15px;
  }
  #downloadButton {
    background: white;
    cursor: pointer;
    bottom: 15px;
  }
  .supplement-sg-number{
    font-size: 19px;
    font-weight: 500;
    line-height: 27px;
  }
.btn-grp-prime .MuiToggleButtonGroup-root {
	display: flex;
    flex-wrap: wrap;
    
}

.btn-grp-prime .MuiToggleButtonGroup-root .MuiButtonBase-root {
	color: #0000ff;
	font-size: 10px;
	height: 42px;
	min-width: 80px;
	border: none;
	background-color: #f3f4f7;
	border-radius: 18px;
}
.btn-grp-prime .MuiToggleButtonGroup-root .Mui-selected {
	border-radius: 24px;
	font-size: 13px;
	font-weight: 400;
	background: linear-gradient(135.21deg, #d831b4 0%, #6241e9 100%);
	color: #ffffff;
	z-index: 10;
}
.cell-wrap-text{
    white-space: normal !important;
}

.MuiTooltip-tooltip {
	background-color: rgb(255 255 255 / 100%) !important;
    box-shadow: 0 0 15px rgb(0 0 0 / 15%);
}
.MuiTooltip-tooltip {
    color: #4c4c4c !important;
    padding: 4px 18px !important;
}
.MuiTooltip-tooltip ul {
	max-height: 193px;
	overflow-y: auto;
	padding-right: 5px;
}
.MuiTooltip-tooltip ul li {
	color: #000;
	font-size: 14px;
	padding: 4px 0 4px 16px;
	display: block;
	position: relative;
}
.MuiTooltip-tooltip ul li:before {
	content: "";
	position: absolute;
	left: 0;
	top: 8px;
	width: 5px;
	height: 5px;
	background: #000; 
	border-radius: 50%;
}
/*.MuiTooltip-tooltip ul li::marker {
	display: none;
}*/
.MuiTooltip-arrow {
    color: rgb(255 255 255 / 100%) !important;
}

.custom-tags-table .ag-body-viewport .ag-center-cols-clipper {
	height: auto !important;
	min-height: 28px !important;
}
.custom-tags-table .ag-body-viewport .ag-center-cols-container {
	height: auto !important;
	min-height: 28px !important;
}
.custom-tags-table.ag-theme-balham .ag-row {
	height: auto !important;
	min-height: 28px !important;
	white-space: inherit;
	position: relative;
}
.custom-tags-table .btn-grp-prime .MuiToggleButtonGroup-root {
	flex-wrap: wrap;
}
.custom-tags-table.ag-theme-balham .ag-ltr .ag-cell:last-child {
	position: relative;
	z-index: 0;
}
.custom-tags-table.ag-theme-balham .ag-ltr .ag-cell:last-child .ag-react-container>div {
	height: auto !important;
}
.custom-tags-table .btn-grp-prime .MuiToggleButtonGroup-root .MuiButtonBase-root {
	margin: 2px;
	height: 28px;
	padding: 0 12px;
	color: #000;
	border-radius: 3px;
	font-size: 13px;
}
.custom-tags-table .btn-grp-prime .MuiToggleButtonGroup-root .Mui-selected {
	background: #323232;
	color: #fff; 
}

.ui.segment.transparent .microbe {
	margin-right: 20px;
}

.ag-center-cols-viewport {
	overflow-y: auto !important;
}
.custom-tags-table.ag-theme-balham .ag-row {
	transform: inherit !important;
}

.ag-cell-focus,.ag-cell-no-focus{
	border:none !important;
  }
  /* This CSS is to not apply the border for the column having 'no-border' class */
  .no-border.ag-cell:focus{
	border:none !important;
	outline: none;
  }
 
  .box_loader .ui.active {
	  position: absolute !important;
	  /* top: 10em !important; */
	  background-color: rgb(114 109 109 / 85%);
	  z-index: 2;
	  /* left: 2% !important; */
	  /* height: calc(100% - 14em) !important;
	  width: calc(100% - 4%); */
	}
	.ag-header-cell-label .ag-header-cell-text {
		white-space: pre-wrap !important;
	 }

	 /* #react-select-2-listbox,
	 .css-1s2u09g-control,
	 .css-3iigni-container,
	 .css-1pahdxg-control:focus
	 {
		 width: 14rem !important;
	 } */
.data-filter-flex {
	align-items: center;
	flex-wrap: wrap;
}
.unsuccesful-checkbox {
	padding-left: 19px;
	position: relative;
}
.unsuccesful-checkbox input{
	position: absolute;
	left: 0px;
	top: 3px;
}
.mb-20 {
	margin-bottom: 20px !important;
} 
.colorSquare-box {
	/*padding: 3px 10px;
    font-size: 1em;
     background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem; */
    color: rgba(0, 0, 0, 0.87);
	position: relative;
}
.colorSquare {
	position: absolute;
    right: 2px;
    top: 0;
}
.uploadImgbox {
	max-width: 150px;
	margin: 10px 0;
}
.h-100 {
	height: 100%;
}
.allergen-file-block {
	position: relative;
	line-height: 1.21428571em;
    padding: 0;
    font-size: 1em;
    background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
}

.allergen-file-block input {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	cursor: pointer;
}
.choose-file-btn {
	width: 100%;
}
.allergen-img {
	margin-left: auto;
	margin-right: auto;
}
.allergen-img img {
	max-width: 100%;
}
.allergen-input-box{
	width: 79% !important;
}
.allergen-toggle-btn{
	margin-left: 82%;
    margin-top: -78px;
}
.allergen-radio-btn{
	top: 5px;
}

 .pdf-wrap {
  font-family: arial;
  padding: 0 20px;
}
pre{
  display: inline-block;
  font-family: arial !important;
  line-height: 0 !important;
}
.supplement-heading {
  font-size: 48px; 
  font-weight: bold; 
  line-height: 54px;
}
.main-heading {
  font-size: 41px;
  line-height: 48px;
}
.supplement-info {
  font-size: 19px;
  font-weight: 500;
  line-height: 34px;
}
.border-top10 {
  border-top: 7px solid #000 !important;
}
.border-bottom10 {
  border-bottom: 7px solid #000 !important;
}
.info-heading {
  font-size: 19px;
  font-weight: bold;
  border-bottom: 2px solid #323232;
  padding: 10px 0 10px 0;
}
.info-heading img {
  max-width: 16px;
  vertical-align: 0px;
  padding-left: 2px;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 100%;
}
.main-row {
  display: flex;
  margin: 0 -20px;
}
.main-col {
  padding: 0 20px;
}
.info-row {
  display: flex;
  align-items: center;
  width: 100%;
  border-top:solid 2px #323232;
}
.info-col {
  font-size: 19px;
  font-weight: 500;
  padding: 11px 0;
  line-height: 22px;
  width: 85%;
}
.info-cols{
  font-size: 19px;
  font-weight: 500;
  padding: 11px 0;
  line-height: 22px;
  width: 15%;
} 

.w-33 {
  width: 50%;
}
.w-66 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
.w-25 {
  width: 25%;
}
.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.ml-auto {
  margin-left: auto;
}
.font-24 {
  font-size: 19px !important;
}
.fw-600 {
  font-weight: bold !important;
}
.info-dtl {
  font-size: 18px;
  margin-top: 13px;
  margin-bottom: 7px;
}
.info-dtl strong,
.info-dtl span {
  display:inline-block !important;
}
.mt-20 {
  margin-top: 18px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.info-dtl div {
  margin-top: 3px;
  line-height: 23px;
}
.info-dtl img {
  min-width: 14px;
  vertical-align: 0px;
  margin-right: 4px;
}
.supplement-mg {
  font-size: 17px;
  padding: 3px 0;
  width: 255px;
  display: inline-block;
  border-bottom:solid 2px #323232;
}
.supplement-mg + .supplement-mg::before {
  display: inline-block;
  white-space: pre;
  /* content: ", ";  */
}

.supplement-mg span {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  display: inline-block;
  margin-left: 7px;
  margin-right: 7px;
  vertical-align: 3px;
}
strong{
  display: initial !important;
}
.pro{
  display: inline-block;
  width: 90%;
  font-size: 18px;
  font-weight: 500;
  padding: 4px 0;
  line-height: 22px;
}
.pro-values{
  display: inline-block;
  width: 10%;
  text-align: right;
  font-weight: 600 !important;
  font-size: 19px !important;
}
.img-arrow{
  display: inline-block;
  width: 11px;
  position: relative;
  right: 2px;
}
.info-sub-row {
  display: flex;
  align-items: center;
}
.info-blank-space {
  min-width: 15px;
}
#downloadButton {
  background: white;
  cursor: pointer;
  bottom: 15px;
}
.css-hf3w1f-word-removed{
    background: none !important;
}
.css-1n5o7vh-diff-container{
    width: 800px !important;
}
strong{
    display: block;
    padding-bottom: 1%;
}
.post-params{
    padding-bottom: 3%;
}
.css-cnnxkz-diff-added{
    background: #f7f7f7 !important;
}
.css-cncyp1-word-diff{
    background: #f7f7f7 !important;
}
.css-rq9a2a-diff-removed{
    background: #f7f7f7 !important;
}
.css-vl0irh-content{
    background: #f7f7f7 !important;
}
.css-17vezug-marker{
    background: #f7f7f7 !important;
}
.css-1ks9aww-gutter{
    background: #f7f7f7 !important;
}
.css-17vezug-marke{
    display: none !important;
}
.css-17vezug-marker pre{
    display: none !important;
}
.formulation-rationale__surveys__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    column-gap: 10px;
    grid-template-areas:
        "headerOne headerTwo"
        "contentOne contentTwo"
        "headerThree headerFour"
        "contentThree contentFour"
        "headerFive headerSix"
        "contentFive contentSix";
}

.rn-pos {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 15px;
    padding-top: 0px;
}

.rn-pos textarea {
    width: 100%;
}

.mt-30 {
    margin-top: 30px;
}

.font-18 {
    font-size: 18px;
}

.mnh-auto {
    height: auto;
    min-height: auto;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-15 {
    margin-right: 15px !important;
}
.red-bg input {
    background-color: red !important;
}
.green-bg input  {
    background-color: green !important;
}
.yellow-bg input {
    background-color: yellow !important;
}
/* input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
} */
.alg-info {
    position: relative;
    display: inline-block;
    vertical-align: 1px;
    z-index: 999;
}
.alg-info img {
    cursor: pointer;
    vertical-align:middle
}
.alg-info-box {
    background: #fff;
    box-shadow: 0 3px 15px rgb(0 0 0 / 10%);
    position: absolute;
    bottom: 24px;
    left: 50%;
    width: 200px;
    padding: 15px;
    transform: translate(-50%, 0);
    z-index: 1;
    display: none;
}
.alg-info:hover .alg-info-box {
    display: block;
}
.alg-info-box:after {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -10px;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    border-top: solid 10px #fff;
    bottom: -10px;
}
.alg-info-box ul {
    margin: 0;
    padding: 0;
    max-height: 92px;
    overflow-y: auto;
}
.alg-info-box ul li {
    padding: 2px 0;
    display: flex;
    align-items: center;
}

.alg-info-box ul li:nth-child(odd) {
    background: #f7f7f7;
}
.alg-info-box ul li:nth-child(even) {
    background: #fff;
}
.alg_icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
}
.alg_text {
    padding-left: 5px;
    display: inline-block;
}

.cs-list {
    padding-left: 20px;
}
.cs-list li {
    list-style-type: disc;
    font-size: 15px;
    padding: 4px 0;
}
.cs-list li ul li {
    list-style-type: circle;
}
.cs-list ul {
    padding-top: 10px;
}
.cs-box {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
}
.cs-body {
    min-height: calc(100vh - 330px);
}
.text-center {
    text-align: center !important;
}
.m-0 {
    margin: 0 !important;
}
.pe-4 {
    padding-right: 1.5rem !important;
}
.pb-2 {
    padding-bottom: 1rem !important;
}
.pe-1 {
    padding-right: 0.5rem !important;
}
.ps-4 {
    padding-left: 1.5rem !important;
}
.align-items-center {
    align-items: center;
}
.d-inline-flex {
    display: inline-flex;
}
.ui.labeled.icon.button.ps-1-5em {
    padding-left: 1.5em !important;
}
.mt-2 {
    margin-top: 1rem !important;
}
.mb-2 {
    margin-bottom: 1rem !important;
}
.mb-1 {
    margin-bottom: 0.5rem !important;
}
.me-3 {
    margin-right: 1.5rem !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.mt-5 {
    margin-top: 3rem !important;
}
.mb-5 {
    margin-bottom: 3rem !important;
}
.theme-btn {
    background-color: #1B1C1D;
    color: #FFFFFF;
    text-shadow: none;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    cursor: pointer;
}
.gray-btn {
    background-color: #696969;
    color: #FFFFFF;
    text-shadow: none;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    cursor: pointer;
}
.theme-text {
    color: #21ba45 !important;
}
.cs-box .row {
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;
}
.cs-box .col-md-6 {
    width: 50%;
    padding: 0 10px;
}

.custom-table table {
    border-top: solid 1px #ccc;
    padding: 0;
}
.custom-table tr td {
    font-size: 15px;
    text-align: center;
    padding: 7px;
    border-bottom: solid 1px #ccc;
    border-left: solid 1px #ccc;
}
.custom-table tr td:last-child {
    border-right: solid 1px #ccc;
}
.cs-label {
    font-size: 13px;
    line-height: 18px;
}
.border-r {
    border-right: solid 1px #ccc;
}
.cs-border-l {
    border-left: solid 1px #ccc;
}
.border-b {
    border-bottom: solid 1px #ccc;
}
.sf-box {
    border: solid 1px #333;
    padding: 10px;
    max-width: 530px;
    margin: 0 auto;
}
.sf-box strong {
    font-family: arial;
    font-weight: 700;
}
.sf-inner-box {
    border: solid 1px #ccc;
    padding: 10px;
    border-bottom: solid 2px #333;
}
.sf-heading1 {
    font-size: 37px;
    color: #000;
    font-weight: 900;
    padding-bottom: 10px;
    font-family: arial;
}
.sf-heading2 {
    font-size: 17px;
    color: #000;
    font-weight: 600;
    padding-bottom: 10px;
}
.sf-table {
    border-top: solid 3px #333;
}
.sf-table tr td {
    font-size: 13px;
    padding: 7px 5px;
    border-bottom: solid 1px #ccc;
    font-weight: 600;
}
.sf-table tr:nth-child(2) td {
    padding: 7px 0;
}
.sf-table tr th {
    font-size: 14px;
    font-weight: 600;
    padding: 7px 5px;
    border-bottom: solid 1px #333;
}
.sf-heading3 {
    font-size: 13px;
    padding: 7px 5px;
    line-height: 18px;
    font-weight: 600;
}
.p-0 {
    padding: 0 !important;
}
.cs-w-60 {
    width: 60%;
}
.cs-w-40 {
    width: 40%;
}
.cs-w-20 {
    width: 20%;
}
.sf-right-box {
    border: solid 1px #333;
    padding: 10px;
    text-align: center;
}
.ps-box {
    border: solid 1px #333;
    padding: 10px;
    max-width: 430px;
    margin: 0 auto;
}
.ps-heading1 {
    font-size: 22px;
    color: #000;
    font-weight: 900;
}
.ps-heading2 {
    font-size: 14px;
    color: #000;
    font-weight: 900;
}
.ps-heading3 {
    font-size: 12px;
    color: #000;
    font-weight: 900;
}
.qr-bar {
    max-width: 100%;
}
.rpb-box {
    border: solid 1px #333;
    padding: 10px;
    max-width: 560px;
    margin: 0 auto;
}
.cs-common-text p {
    font-size: 15px;
    line-height: 22px;
}
.mxw-100 {
    max-width: 100%;
}
.custom-header-table tr th {
    border-style: solid;
    border-color: #BDC3C7;
    padding: 12px 12px;
    border-width: 0;
    line-height: 19px;
    font-size: 15px;
    background-color: #f5f7f7;    
    border-bottom: solid 1px #ccc;
    border-left: solid 1px #ccc;
    position: relative;
}
.custom-header-table tr th:first-child {
    border-left: solid 1px #ccc;
}
.custom-header-table tr th:last-child {
    border-right: solid 1px #ccc;
}
.cs-w-30 {
    width: 30%;
}
.cs-w-25 {
    width: 25%;
}
.w-30 {
    width: 30% !important;
}
.w-70 {
    width: 70% !important;
}
.ps-5 {
    padding-left: 3rem !important;
}
.quit-btn {
    background: #DB2828 !important;   
}
.pause-btn {
    background: #ffeb3bc7 !important;
    color: #333 !important;
}
.ms-2 {
    margin-left: 0.5rem !important;
}
.ui.form-control.w-100 input {
    width: 100% !important;
}
.weighing-ingredients-table.custom-header-table tr th,
.weighing-ingredients-table.custom-header-table tr td {
    padding: 12px 7px;
    line-height: 18px;
    font-size: 13px;
}
.text-nowrap {
    text-wrap: nowrap;
}
.text-green {
    color: #21ba45 !important;
}
.mxw-100 {
    max-width: 100%;
}
.lineh-20 {
    line-height: 18px;
}
.ps-3 {
    padding-left: 1.5rem !important;
}
.pe-3 {
    padding-right: 1.5rem !important;
}
.pt-3 {
    padding-top: 1.5rem !important;
}
.rfp-title {
    font-size: 15px;
    font-weight: 600;
}
.rfp-md-title {
    font-size: 14px;
}
.rfp-border {
    border: solid 1px #ccc;
}
.rfp-main-table tr td {
    padding: 5px;
}
.fw-500 {
    font-weight: 500 !important;
}
.text-start {
    text-align: left !important;
}
.rfp-w-50 {
    width: 50%;
}
.rfp-main-table {
    font-weight: 600;
    line-height: 20px;
}
.pt-2 {
    padding-top: 1rem !important;
}
.font-14 {
    font-size: 14px !important;
}
.ui.form-control.w-100.text-center input {
    text-align: center;
}
.order-count {
    padding-left: 10px;
}
.order-count li {
    list-style-type: none;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.ui.form .ui.input.error > input {
    background-color: #FFF6F6;
    border-color: #E0B4B4;
    color: #9F3A38;
    box-shadow: none;
}
