.filepond--list-scroller ul li::before {
    display: none;
}

.date-picker {
    width: inherit;
    margin-top: 3%;
    border: 1px solid gray;
    opacity: 0.4;
    padding: 4%;
    border-radius: 4px;
}

.upload-documents-input {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 3px;
    background-color: gray;
    border-radius: 4px;
}

.is-available {
    width: 100%;
    padding: 7px 0;
    border: 1px solid gray;
    /* opacity: 0.5; */
}

.is-available:focus {
    border: 1px solid gray;
}

.tooltip-name {
    border: 1px solid;
    white-space: hidden;
    overflow: hidden;
    position: absolute;
    top: 50px;
    width: 200px;
    max-width: 200px;
    background-color: black;
    color: aliceblue;
    border-radius: 4px;
    padding: 5%;
    display: none;
    z-index: 1;
}

.tooltip-name :focus {
    width: 100px;
    max-width: 100px;
}

.align-items-end {
    align-items: end
}
.field-ui {
    background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    padding: 0.67857143em 1em;
    border-radius: 0.28571429rem;
}
.inventory_upload_document {
    max-height: 435px;
    overflow-y: auto;
    overflow-x: hidden;
}