.btn-grp-prime .MuiToggleButtonGroup-root {
	display: flex;
    flex-wrap: wrap;
    
}

.btn-grp-prime .MuiToggleButtonGroup-root .MuiButtonBase-root {
	color: #0000ff;
	font-size: 10px;
	height: 42px;
	min-width: 80px;
	border: none;
	background-color: #f3f4f7;
	border-radius: 18px;
}
.btn-grp-prime .MuiToggleButtonGroup-root .Mui-selected {
	border-radius: 24px;
	font-size: 13px;
	font-weight: 400;
	background: linear-gradient(135.21deg, #d831b4 0%, #6241e9 100%);
	color: #ffffff;
	z-index: 10;
}
.cell-wrap-text{
    white-space: normal !important;
}

.MuiTooltip-tooltip {
	background-color: rgb(255 255 255 / 100%) !important;
    box-shadow: 0 0 15px rgb(0 0 0 / 15%);
}
.MuiTooltip-tooltip {
    color: #4c4c4c !important;
    padding: 4px 18px !important;
}
.MuiTooltip-tooltip ul {
	max-height: 193px;
	overflow-y: auto;
	padding-right: 5px;
}
.MuiTooltip-tooltip ul li {
	color: #000;
	font-size: 14px;
	padding: 4px 0 4px 16px;
	display: block;
	position: relative;
}
.MuiTooltip-tooltip ul li:before {
	content: "";
	position: absolute;
	left: 0;
	top: 8px;
	width: 5px;
	height: 5px;
	background: #000; 
	border-radius: 50%;
}
/*.MuiTooltip-tooltip ul li::marker {
	display: none;
}*/
.MuiTooltip-arrow {
    color: rgb(255 255 255 / 100%) !important;
}

.custom-tags-table .ag-body-viewport .ag-center-cols-clipper {
	height: auto !important;
	min-height: 28px !important;
}
.custom-tags-table .ag-body-viewport .ag-center-cols-container {
	height: auto !important;
	min-height: 28px !important;
}
.custom-tags-table.ag-theme-balham .ag-row {
	height: auto !important;
	min-height: 28px !important;
	white-space: inherit;
	position: relative;
}
.custom-tags-table .btn-grp-prime .MuiToggleButtonGroup-root {
	flex-wrap: wrap;
}
.custom-tags-table.ag-theme-balham .ag-ltr .ag-cell:last-child {
	position: relative;
	z-index: 0;
}
.custom-tags-table.ag-theme-balham .ag-ltr .ag-cell:last-child .ag-react-container>div {
	height: auto !important;
}
.custom-tags-table .btn-grp-prime .MuiToggleButtonGroup-root .MuiButtonBase-root {
	margin: 2px;
	height: 28px;
	padding: 0 12px;
	color: #000;
	border-radius: 3px;
	font-size: 13px;
}
.custom-tags-table .btn-grp-prime .MuiToggleButtonGroup-root .Mui-selected {
	background: #323232;
	color: #fff; 
}

.ui.segment.transparent .microbe {
	margin-right: 20px;
}

.ag-center-cols-viewport {
	overflow-y: auto !important;
}
.custom-tags-table.ag-theme-balham .ag-row {
	transform: inherit !important;
}

.ag-cell-focus,.ag-cell-no-focus{
	border:none !important;
  }
  /* This CSS is to not apply the border for the column having 'no-border' class */
  .no-border.ag-cell:focus{
	border:none !important;
	outline: none;
  }
 
  .box_loader .ui.active {
	  position: absolute !important;
	  /* top: 10em !important; */
	  background-color: rgb(114 109 109 / 85%);
	  z-index: 2;
	  /* left: 2% !important; */
	  /* height: calc(100% - 14em) !important;
	  width: calc(100% - 4%); */
	}
	.ag-header-cell-label .ag-header-cell-text {
		white-space: pre-wrap !important;
	 }

	 /* #react-select-2-listbox,
	 .css-1s2u09g-control,
	 .css-3iigni-container,
	 .css-1pahdxg-control:focus
	 {
		 width: 14rem !important;
	 } */
.data-filter-flex {
	align-items: center;
	flex-wrap: wrap;
}
.unsuccesful-checkbox {
	padding-left: 19px;
	position: relative;
}
.unsuccesful-checkbox input{
	position: absolute;
	left: 0px;
	top: 3px;
}
.mb-20 {
	margin-bottom: 20px !important;
} 
.colorSquare-box {
	/*padding: 3px 10px;
    font-size: 1em;
     background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem; */
    color: rgba(0, 0, 0, 0.87);
	position: relative;
}
.colorSquare {
	position: absolute;
    right: 2px;
    top: 0;
}
.uploadImgbox {
	max-width: 150px;
	margin: 10px 0;
}
.h-100 {
	height: 100%;
}
.allergen-file-block {
	position: relative;
	line-height: 1.21428571em;
    padding: 0;
    font-size: 1em;
    background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
}

.allergen-file-block input {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	cursor: pointer;
}
.choose-file-btn {
	width: 100%;
}
.allergen-img {
	margin-left: auto;
	margin-right: auto;
}
.allergen-img img {
	max-width: 100%;
}
.allergen-input-box{
	width: 79% !important;
}
.allergen-toggle-btn{
	margin-left: 82%;
    margin-top: -78px;
}
.allergen-radio-btn{
	top: 5px;
}