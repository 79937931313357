.notes{
    border-radius: 4px;
    height: 45px;
    margin-top: 1%;
}
.inventory{
    height: 34px;
    margin-top: 1px;
    opacity: 0.6;
    width: 100%;
    border: 1px solid gray;
}
.inventory:focus{
    border: 1px solid gray;
}
.isPrime{
    text-transform: capitalize;
    width: 100%;
}
.notesInventory{
    padding-left: 2%;
    line-height: 0;
}
textarea.full_opacity {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
}
textarea.full_opacity:focus {
    outline:none;
}
.cs-checkbox-label {
    margin-left: 4px;
    margin-right: 15px;
    vertical-align: 1px;
}
#page-size {
    min-width:100%;
}
.ui[class*="right labeled"].icon.button.sr-button {
    padding-right: 2.5em !important;
    padding-left: 1em !important;
}
.require_fields{
    color:red;
}
.mt-5 {
    margin-top: 0px !important;
}
.w-100 {
    width:100% !important;
}
.white-textarea {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0;
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    background: #fff;
    resize: none;
    height: 80px;
}
.pl-0 {
    padding-left: 0 !important;
}