
 .pdf-wrap {
    font-family: arial;
    padding: 0 20px;
  }
  pre{
    display: inline-block;
    font-family: arial !important;
    line-height: 0 !important;
  }
  .supplement-heading {
    font-size: 48px; 
    font-weight: bold; 
    line-height: 54px;
  }
  .main-heading {
    font-size: 41px;
    line-height: 48px;
    /* padding-bottom: 10px; */
  }
  .supplement-info {
    font-size: 19px;
    font-weight: 500;
    line-height: 34px;
  }
  .border-top10 {
    border-top: 7px solid #000 !important;
  }
  .border-bottom10 {
    border-bottom: 7px solid #000 !important;
  }
  .info-heading {
    font-size: 19px;
    font-weight: bold;
    border-bottom: 2px solid #323232;
    padding: 10px 0 10px 0;
  }
  .info-heading img {
    max-width: 16px;
    vertical-align: 0px;
    padding-left: 2px;
  }
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .fw-500 {
    font-weight: 500 !important;
  }
  .w-60 {
    width: 60%;
  }
  .w-40 {
    width: 40%;
  }
  .w-50 {
    width: 100%;
  }
  .main-row {
    display: flex;
    margin: 0 -20px;
  }
  .main-col {
    padding: 0 20px;
  }
  .info-row {
    display: flex;
    align-items: center;
    width: 100%;
    border-top:solid 2px #323232;
  }
  .info-col {
    font-size: 19px;
    font-weight: 500;
    padding: 11px 0;
    line-height: 22px;
    width: 85%;
  }
  .info-cols{
    font-size: 19px;
    font-weight: 500;
    padding: 11px 0;
    line-height: 22px;
    width: 15%;
  } 
  
  .w-33 {
    width: 50%;
  }
  .w-66 {
    width: 50%;
  }
  .w-75 {
    width: 75%;
  }
  .w-25 {
    width: 25%;
  }
  .d-flex {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  .ml-auto {
    margin-left: auto;
  }
  .font-24 {
    font-size: 19px !important;
  }
  .fw-600 {
    font-weight: bold !important;
  }
  .info-dtl {
    font-size: 18px;
    margin-top: 13px;
    margin-bottom: 7px;
  }
  .info-dtl strong,
  .info-dtl span {
    display:inline-block !important;
  }
  .mt-20 {
    margin-top: 18px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .info-dtl div {
    margin-top: 3px;
    line-height: 23px;
  }
  .info-dtl img {
    min-width: 14px;
    vertical-align: 0px;
    margin-right: 4px;
  }
  .supplement-mg {
    font-size: 17px;
    padding: 3px 0;
    width: 255px;
    display: inline-block;
    border-bottom:solid 2px #323232;
  }
  .supplement-mg + .supplement-mg::before {
    display: inline-block;
    white-space: pre;
    /* content: ", ";  */
  }
  
  .supplement-mg span {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #000;
    display: inline-block;
    margin-left: 7px;
    margin-right: 7px;
    vertical-align: 3px;
  }
  strong{
    display: initial !important;
  }
  .pro{
    display: inline-block;
    width: 90%;
    font-size: 18px;
    font-weight: 500;
    padding: 4px 0;
    line-height: 22px;
  }
  .pro-values{
    display: inline-block;
    width: 10%;
    text-align: right;
    font-weight: 600 !important;
    font-size: 19px !important;
  }
  .img-arrow{
    display: inline-block;
    width: 11px;
    position: relative;
    right: 2px;
  }
  .info-sub-row {
    display: flex;
    align-items: center;
  }
  .info-blank-space {
    min-width: 15px;
  }
  #downloadButton {
    background: white;
    cursor: pointer;
    bottom: 15px;
  }
  .supplement-sg-number{
    font-size: 19px;
    font-weight: 500;
    line-height: 27px;
  }