.formulation-rationale__surveys__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    grid-template-areas:
        "headerOne headerTwo"
        "contentOne contentTwo"
        "headerThree headerFour"
        "contentThree contentFour"
        "headerFive headerSix"
        "contentFive contentSix";
}

.rn-pos {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 15px;
    padding-top: 0px;
}

.rn-pos textarea {
    width: 100%;
}

.mt-30 {
    margin-top: 30px;
}

.font-18 {
    font-size: 18px;
}

.mnh-auto {
    height: auto;
    min-height: auto;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-15 {
    margin-right: 15px !important;
}
.red-bg input {
    background-color: red !important;
}
.green-bg input  {
    background-color: green !important;
}
.yellow-bg input {
    background-color: yellow !important;
}
/* input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
} */
.alg-info {
    position: relative;
    display: inline-block;
    vertical-align: 1px;
    z-index: 999;
}
.alg-info img {
    cursor: pointer;
    vertical-align:middle
}
.alg-info-box {
    background: #fff;
    box-shadow: 0 3px 15px rgb(0 0 0 / 10%);
    position: absolute;
    bottom: 24px;
    left: 50%;
    width: 200px;
    padding: 15px;
    transform: translate(-50%, 0);
    z-index: 1;
    display: none;
}
.alg-info:hover .alg-info-box {
    display: block;
}
.alg-info-box:after {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -10px;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    border-top: solid 10px #fff;
    bottom: -10px;
}
.alg-info-box ul {
    margin: 0;
    padding: 0;
    max-height: 92px;
    overflow-y: auto;
}
.alg-info-box ul li {
    padding: 2px 0;
    display: flex;
    align-items: center;
}

.alg-info-box ul li:nth-child(odd) {
    background: #f7f7f7;
}
.alg-info-box ul li:nth-child(even) {
    background: #fff;
}
.alg_icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
}
.alg_text {
    padding-left: 5px;
    display: inline-block;
}

.cs-list {
    padding-left: 20px;
}
.cs-list li {
    list-style-type: disc;
    font-size: 15px;
    padding: 4px 0;
}
.cs-list li ul li {
    list-style-type: circle;
}
.cs-list ul {
    padding-top: 10px;
}
.cs-box {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
}
.cs-body {
    min-height: calc(100vh - 330px);
}
.text-center {
    text-align: center !important;
}
.m-0 {
    margin: 0 !important;
}
.pe-4 {
    padding-right: 1.5rem !important;
}
.pb-2 {
    padding-bottom: 1rem !important;
}
.pe-1 {
    padding-right: 0.5rem !important;
}
.ps-4 {
    padding-left: 1.5rem !important;
}
.align-items-center {
    align-items: center;
}
.d-inline-flex {
    display: inline-flex;
}
.ui.labeled.icon.button.ps-1-5em {
    padding-left: 1.5em !important;
}
.mt-2 {
    margin-top: 1rem !important;
}
.mb-2 {
    margin-bottom: 1rem !important;
}
.mb-1 {
    margin-bottom: 0.5rem !important;
}
.me-3 {
    margin-right: 1.5rem !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.mt-5 {
    margin-top: 3rem !important;
}
.mb-5 {
    margin-bottom: 3rem !important;
}
.theme-btn {
    background-color: #1B1C1D;
    color: #FFFFFF;
    text-shadow: none;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    cursor: pointer;
}
.gray-btn {
    background-color: #696969;
    color: #FFFFFF;
    text-shadow: none;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    cursor: pointer;
}
.theme-text {
    color: #21ba45 !important;
}
.cs-box .row {
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;
}
.cs-box .col-md-6 {
    width: 50%;
    padding: 0 10px;
}

.custom-table table {
    border-top: solid 1px #ccc;
    padding: 0;
}
.custom-table tr td {
    font-size: 15px;
    text-align: center;
    padding: 7px;
    border-bottom: solid 1px #ccc;
    border-left: solid 1px #ccc;
}
.custom-table tr td:last-child {
    border-right: solid 1px #ccc;
}
.cs-label {
    font-size: 13px;
    line-height: 18px;
}
.border-r {
    border-right: solid 1px #ccc;
}
.cs-border-l {
    border-left: solid 1px #ccc;
}
.border-b {
    border-bottom: solid 1px #ccc;
}
.sf-box {
    border: solid 1px #333;
    padding: 10px;
    max-width: 530px;
    margin: 0 auto;
}
.sf-box strong {
    font-family: arial;
    font-weight: 700;
}
.sf-inner-box {
    border: solid 1px #ccc;
    padding: 10px;
    border-bottom: solid 2px #333;
}
.sf-heading1 {
    font-size: 37px;
    color: #000;
    font-weight: 900;
    padding-bottom: 10px;
    font-family: arial;
}
.sf-heading2 {
    font-size: 17px;
    color: #000;
    font-weight: 600;
    padding-bottom: 10px;
}
.sf-table {
    border-top: solid 3px #333;
}
.sf-table tr td {
    font-size: 13px;
    padding: 7px 5px;
    border-bottom: solid 1px #ccc;
    font-weight: 600;
}
.sf-table tr:nth-child(2) td {
    padding: 7px 0;
}
.sf-table tr th {
    font-size: 14px;
    font-weight: 600;
    padding: 7px 5px;
    border-bottom: solid 1px #333;
}
.sf-heading3 {
    font-size: 13px;
    padding: 7px 5px;
    line-height: 18px;
    font-weight: 600;
}
.p-0 {
    padding: 0 !important;
}
.cs-w-60 {
    width: 60%;
}
.cs-w-40 {
    width: 40%;
}
.cs-w-20 {
    width: 20%;
}
.sf-right-box {
    border: solid 1px #333;
    padding: 10px;
    text-align: center;
}
.ps-box {
    border: solid 1px #333;
    padding: 10px;
    max-width: 430px;
    margin: 0 auto;
}
.ps-heading1 {
    font-size: 22px;
    color: #000;
    font-weight: 900;
}
.ps-heading2 {
    font-size: 14px;
    color: #000;
    font-weight: 900;
}
.ps-heading3 {
    font-size: 12px;
    color: #000;
    font-weight: 900;
}
.qr-bar {
    max-width: 100%;
}
.rpb-box {
    border: solid 1px #333;
    padding: 10px;
    max-width: 560px;
    margin: 0 auto;
}
.cs-common-text p {
    font-size: 15px;
    line-height: 22px;
}
.mxw-100 {
    max-width: 100%;
}
.custom-header-table tr th {
    border-style: solid;
    border-color: #BDC3C7;
    padding: 12px 12px;
    border-width: 0;
    line-height: 19px;
    font-size: 15px;
    background-color: #f5f7f7;    
    border-bottom: solid 1px #ccc;
    border-left: solid 1px #ccc;
    position: relative;
}
.custom-header-table tr th:first-child {
    border-left: solid 1px #ccc;
}
.custom-header-table tr th:last-child {
    border-right: solid 1px #ccc;
}
.cs-w-30 {
    width: 30%;
}
.cs-w-25 {
    width: 25%;
}
.w-30 {
    width: 30% !important;
}
.w-70 {
    width: 70% !important;
}
.ps-5 {
    padding-left: 3rem !important;
}
.quit-btn {
    background: #DB2828 !important;   
}
.pause-btn {
    background: #ffeb3bc7 !important;
    color: #333 !important;
}
.ms-2 {
    margin-left: 0.5rem !important;
}
.ui.form-control.w-100 input {
    width: 100% !important;
}
.weighing-ingredients-table.custom-header-table tr th,
.weighing-ingredients-table.custom-header-table tr td {
    padding: 12px 7px;
    line-height: 18px;
    font-size: 13px;
}
.text-nowrap {
    text-wrap: nowrap;
}
.text-green {
    color: #21ba45 !important;
}
.mxw-100 {
    max-width: 100%;
}
.lineh-20 {
    line-height: 18px;
}
.ps-3 {
    padding-left: 1.5rem !important;
}
.pe-3 {
    padding-right: 1.5rem !important;
}
.pt-3 {
    padding-top: 1.5rem !important;
}
.rfp-title {
    font-size: 15px;
    font-weight: 600;
}
.rfp-md-title {
    font-size: 14px;
}
.rfp-border {
    border: solid 1px #ccc;
}
.rfp-main-table tr td {
    padding: 5px;
}
.fw-500 {
    font-weight: 500 !important;
}
.text-start {
    text-align: left !important;
}
.rfp-w-50 {
    width: 50%;
}
.rfp-main-table {
    font-weight: 600;
    line-height: 20px;
}
.pt-2 {
    padding-top: 1rem !important;
}
.font-14 {
    font-size: 14px !important;
}
.ui.form-control.w-100.text-center input {
    text-align: center;
}
.order-count {
    padding-left: 10px;
}
.order-count li {
    list-style-type: none;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.ui.form .ui.input.error > input {
    background-color: #FFF6F6;
    border-color: #E0B4B4;
    color: #9F3A38;
    box-shadow: none;
}