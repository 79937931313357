.formulation-rationale__health-survey__header {
    grid-area: headerOne;
}

.formulation-rationale__health-survey__content {
    grid-area: contentOne;
    height: 500px;
    overflow-y: auto;
    padding-bottom: 5px;
}

.formulation-rationale__health-survey {
    grid-area: contentOne;
    height: 230px;
    overflow-y: auto;
    padding-bottom: 5px;
}

.formulation-rationale__health-survey .formulation-rationale__health-survey__content,
.formulation-rationale__health-survey__content .formulation-rationale__health-survey__content {
    grid-area: inherit;
    height: inherit;
    overflow-y: inherit;
}

.formulation-rationale__health-survey__item {
    margin-bottom: 1em;
}
.table.position-relative th {
    position: relative !important;
}